<template>
  <a-form-model
    ref="ratesForm"
    :model="localRates"
    :rules="rateRules"
    hideRequiredMark
  >
    <div class="d-flex flex-column">
      <a-row :gutter="[16, 16]">
        <a-col :span="12">
          <span class="text-uppercase mr-2">{{ $t('buttons.btnBuy') + ' ' + $t('common.operation') }}</span>
          <a-row class="mt-3">
            <a-col :span="12" class="pr-1">
              <div class="d-flex flex-grow-1">
                <span class="font-weight-bold">Client:</span>
                <a-checkbox
                  class="ml-auto"
                  :checked="isBuyClientSync"
                  v-if="useAutoImport"
                  :disabled="!isHavePermissionEdit"
                  @change="changeSyncRateHandler($event.target.checked, 'rate_b_inc_import')"
                >
                  <span class="mr-1">Sync</span>
                  <a-tooltip title="If the checkbox is active, BUY-rate will be equal to the global rate settings">
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </a-checkbox>
              </div>
              <a-form-model-item prop="rate_b_inc">
                <a-input-number
                  :value="localRates.rate_b_inc"
                  class="w-100"
                  :default-value="0"
                  :step="0.01"
                  :min="-100"
                  :max="100"
                  :formatter="value => `${value} %`"
                  :parser="customParser"
                  @change="changeRateHandler($event, 'rate_b_inc')"
                  :disabled="isBuyClientSync && useAutoImport || !isHavePermissionEdit"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1">
              <div class="d-flex flex-grow-1">
                <span class="font-weight-bold">Agent:</span>
                <a-checkbox
                  class="ml-auto"
                  :checked="isBuyAgentSync"
                  v-if="useAutoImport"
                  :disabled="!isHavePermissionEdit"
                  @change="changeSyncRateHandler($event.target.checked, 'rate_ba_inc_import')"
                >
                  <span class="mr-1">Sync</span>
                  <a-tooltip title="If the checkbox is active, BUY-rate will be equal to the global rate settings">
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </a-checkbox>
              </div>
              <a-form-model-item prop="rate_ba_inc">
                <a-input-number
                  :value="localRates.rate_ba_inc"
                  class="w-100"
                  :default-value="0"
                  :step="0.01"
                  :min="-100"
                  :max="100"
                  :formatter="value => `${value} %`"
                  :parser="customParser"
                  @change="changeRateHandler($event, 'rate_ba_inc')"
                  :disabled="isBuyAgentSync && useAutoImport || !isHavePermissionEdit"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12">
          <span class="text-uppercase mr-2">{{ $t('buttons.btnSell') }} {{ $t('common.operation') }}</span>
          <a-row class="mt-3">
            <a-col :span="12" class="pr-1">
              <div class="d-flex flex-grow-1">
                <span class="font-weight-bold">Client:</span>
                <a-checkbox
                  class="ml-auto"
                  :checked="isSellClientSync"
                  v-if="useAutoImport"
                  :disabled="!isHavePermissionEdit"
                  @change="changeSyncRateHandler($event.target.checked, 'rate_s_inc_import')"
                >
                  <span class="mr-1">Sync</span>
                  <a-tooltip title="If the checkbox is active, SELL-rate will be equal to the global rate settings">
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </a-checkbox>
              </div>
              <a-form-model-item prop="rate_s_inc">
                <a-input-number
                  :value="localRates.rate_s_inc"
                  class="w-100"
                  :default-value="0"
                  :step="0.01"
                  :min="-100"
                  :max="100"
                  :formatter="value => `${value} %`"
                  :parser="customParser"
                  @change="changeRateHandler($event, 'rate_s_inc')"
                  :disabled="isSellClientSync && useAutoImport || !isHavePermissionEdit"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1">
              <div class="d-flex flex-grow-1">
                <span class="font-weight-bold">Agent:</span>
                <a-checkbox
                  class="ml-auto"
                  :checked="isSellAgentSync"
                  v-if="useAutoImport"
                  :disabled="!isHavePermissionEdit"
                  @change="changeSyncRateHandler($event.target.checked, 'rate_sa_inc_import')"
                >
                  <span class="mr-1">Sync</span>
                  <a-tooltip title="If the checkbox is active, SELL-rate will be equal to the global rate settings">
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </a-checkbox>
              </div>
              <a-form-model-item prop="agentSellRate">
                <a-input-number
                  :value="localRates.rate_sa_inc"
                  class="w-100"
                  :default-value="0"
                  :step="0.01"
                  :min="-100"
                  :max="100"
                  :formatter="value => `${value} %`"
                  :parser="customParser"
                  @change="changeRateHandler($event, 'rate_sa_inc')"
                  :disabled="isSellAgentSync && useAutoImport || !isHavePermissionEdit"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <div class="d-flex justify-content-end">
        <a-button class="mr-3" type="primary" @click="submitHandler" :loading="loading" :disabled="!isAllowSubmit">
          {{ $t('buttons.btnSave') }}
        </a-button>
        <a-button @click="cancelHandler" :disabled="!isChanged">
          {{ $t('buttons.btnCancel') }}
        </a-button>
      </div>
    </div>
  </a-form-model>
</template>

<script>
export default {
  name: 'CurrenciesOperation',
  emits: [
    'update:options',
    'canceled',
    'submitted',
  ],
  props: {
    options: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useAutoImport: {
      type: Boolean,
      default: false,
    },
    isHavePermissionEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      startRates: {
        ...this.initData(),
      },
      rateRules: {
        rate_b_inc: [{ validator: this.validateInputHandler, trigger: 'blur' }],
        rate_ba_inc: [{ validator: this.validateInputHandler, trigger: 'blur' }],
        rate_s_inc: [{ validator: this.validateInputHandler, trigger: 'blur' }],
        rate_sa_inc: [{ validator: this.validateInputHandler, trigger: 'blur' }],
      },
      formValidateStatus: {
        rate_b_inc: true,
        rate_ba_inc: true,
        rate_s_inc: true,
        rate_sa_inc: true,
      },
    }
  },
  computed: {
    localRates: {
      get() {
        return this.options
      },
      set(value) {
        this.$emit('update:options', value)
      },
    },
    isSellClientSync() {
      return this.localRates?.options?.rate_s_inc_import
    },
    isSellAgentSync() {
      return this.localRates?.options?.rate_sa_inc_import
    },
    isBuyClientSync() {
      return this.localRates?.options?.rate_b_inc_import
    },
    isBuyAgentSync() {
      return this.localRates?.options?.rate_ba_inc_import
    },
    isFormValid() {
      return Object.keys(this.formValidateStatus).every((key) => this.formValidateStatus[key] === true)
    },
    isChanged() {
      return JSON.stringify(this.localRates) !== JSON.stringify(this.startRates) && this.isHavePermissionEdit
    },
    isAllowSubmit() {
      return this.isFormValid && this.isChanged && this.isHavePermissionEdit
    },
  },
  watch: {
    isSellClientSync(boolean) {
      if (boolean) {
        this.localRates.rate_s_inc = this.startRates.rate_s_inc
      }
    },
    isSellAgentSync(boolean) {
      if (boolean) {
        this.localRates.rate_sa_inc = this.startRates.rate_sa_inc
      }
    },
    isBuyClientSync(boolean) {
      if (boolean) {
        this.localRates.rate_b_inc = this.startRates.rate_b_inc
      }
    },
    isBuyAgentSync(boolean) {
      if (boolean) {
        this.localRates.rate_ba_inc = this.startRates.rate_ba_inc
      }
    },
  },
  methods: {
    initData() {
      return {
        ...this.options,
      }
    },
    validateInputHandler(rule, value, callback) {
      const isEmpty = value === null || value === undefined || value === ''
      this.formValidateStatus[rule.field] = !isEmpty

      isEmpty ? callback(new Error('Field is required')) : callback()
    },
    customParser(value) {
      return value
        .replace(/[^\d.-]/g, '')
        .replace(/^(-?)(\d*\.?\d*).*/, '$1$2')
    },
    changeRateHandler(value, rateType) {
      this.localRates = { ...this.localRates, [rateType]: value }
    },
    changeSyncRateHandler(value, syncType) {
      this.localRates = {
        ...this.localRates,
        options: {
          ...this.localRates.options,
          [syncType]: value,
        },
      }
    },
    submitHandler() {
      if (this.isAllowSubmit) {
        this.$emit('submitted')

        this.$nextTick(() => {
          this.startRates = { ...this.initData() }
        })
      }
    },
    cancelHandler() {
      if (this.isChanged) {
        this.localRates = {
          ...this.startRates,
        }

        this.$refs.ratesForm.resetFields()

        this.$emit('canceled')
      }
    },
  },
}
</script>
