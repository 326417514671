<script>
export default {
  name: 'CurrenciesStatisticItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    clientRate: {
      type: [Number, String],
      required: true,
    },
    agentRate: {
      type: [Number, String],
      required: true,
    },
    abbr: {
      type: String,
      required: true,
    },
    isGlobal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return [
        {
          name: 'Client',
          rate: this.clientRate,
        },
        {
          name: 'Agent',
          rate: this.agentRate,
        },
      ]
    },
  },
}
</script>

<template>
  <div class="d-flex flex-column">
    <span style="'color: #8C8C8C'">
      <span>{{ title }}</span>
      <span v-if="isGlobal" class="text-gray-5 font-weight-bold"> global</span>
    </span>
    <a-statistic
      v-for="item in items"
      :key="item.name"
      title=""
      :value="item.rate"
    >
      <template #prefix>
        <span class="font-size-14 text-gray-5">{{ item.name }}:</span>
      </template>
      <template #suffix>
        <span class="small">{{ abbr }}</span>
      </template>
    </a-statistic>
  </div>
</template>
