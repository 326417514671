<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-row :gutter="[16, 16]">
          <a-col :span="24">
            <div class="d-flex justify-content-center align-items-center">
              <a-statistic class="mr-3" :title="`${$t('common.base')} ${$t('common.rate')}`" :value="rates.base">
                <template #suffix>
                  <span class="small">{{ currency.abbr }}</span>
                </template>
              </a-statistic>
              <div class="d-block mt-2">
                <a href="javascript:" class="text-primary font-size-28" @click="refreshBaseRate">
                  <a-icon v-if="!refreshBaseLoading" type="sync" />
                  <a-icon v-else type="loading" />
                </a>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <CurrenciesStatisticItem
              title="Buy"
              :abbr="currency.abbr"
              :agent-rate="rates.rateBa"
              :client-rate="rates.rateB"
              is-global
            />
          </a-col>
          <a-col :span="12">
            <CurrenciesStatisticItem
              title="Sell"
              :abbr="currency.abbr"
              :agent-rate="rates.rateSa"
              :client-rate="rates.rateS"
              is-global
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="16">
        <a-card size="small">
          <template #title>
            <span class="font-size-16">
              {{ $t('common.rate') }} adjustments
              <span class="font-weight-bold">(global)</span>
            </span>
          </template>
          <CurrenciesOperations
            :options.sync="currencyRateForm"
            :loading="submitLoading"
            @submitted="editGlobalRatesSubmitHandler"
          />
        </a-card>
      </a-col>
    </a-row>
    <a-divider />
    <a-card size="small" :loading="loadingProjectRates" v-if="isAllowViewProjectRates">
      <template #title>
        <span class="font-size-16">Custom Project Rates</span>
      </template>
      <a-collapse
        v-if="projectRates.length"
        expand-icon-position="left"
        v-model="activeKeys"
      >
        <a-collapse-panel
          v-for="project in projectRates"
          :key="String(project.id)"
          :header="project.name"
        >
          <template #extra>
            <a-tooltip :title="isSyncGlobalRates(project.auto_import) ? 'Equals global rate' : 'Custom rate'" placement="top">
              <a-icon type="api" :class="isSyncGlobalRates(project.auto_import) ? 'text-primary' : 'text-red'" class="mr-auto"/>
            </a-tooltip>
          </template>
          <ProjectRateItem
            :project="project"
            :is-have-permission-edit="isAllowEditProjectRates"
            @update:project="updateProjectHandler"
            @submit="editProjectRates(project)"
            @cancel="hideCollapsePanel(project.id)"
          />
        </a-collapse-panel>
      </a-collapse>
      <a-empty v-else />
    </a-card>
  </div>
</template>
<script>
import CurrenciesRatesService from '../../../services/api/currencies/rates/currencies.rates.service'
import CurrenciesStatisticItem from './atomic/CurrenciesStatisticItem.vue'
import CurrenciesOperations from './atomic/CurrenciesOperations.vue'
import ProjectsService from '../../../services/api/projects/projects.service'
import accessMix from '@/services/accessmix.js'
import { mapState } from 'vuex'
import ProjectRateItem from './atomic/ProjectRateItem.vue'

export default {
  name: 'currencyRateModal.vue',
  components: { ProjectRateItem, CurrenciesOperations, CurrenciesStatisticItem },
  mixins: [accessMix],
  props: ['currencyRateInfo', 'currency', 'importMethods'],
  computed: {
    ...mapState(['user']),
    isAllowViewProjectRates() {
      return this.checkPermissions(this.user.permissions, 'project_rates', 'view')
    },
    isAllowEditProjectRates() {
      return this.checkPermissions(this.user.permissions, 'project_rates', 'edit')
    },
    ratePropertiesStr() {
      return `${this.base}|${this.rateBInc}|${this.rateBAInc}|${this.rateSInc}|${this.rateSAInc}`
    },
    base() {
      return this.rates.base
    },
    rateBInc() {
      return this.currencyRateForm.rate_b_inc
    },
    rateBAInc() {
      return this.currencyRateForm.rate_ba_inc
    },
    rateSInc() {
      return this.currencyRateForm.rate_s_inc
    },
    rateSAInc() {
      return this.currencyRateForm.rate_sa_inc
    },
    currencyId() {
      return this.currency?.id
    },
  },
  watch: {
    ratePropertiesStr() {
      if (this.base > 0) {
        this.rates.rateB = (this.base + (this.base * this.rateBInc / 100)).toFixed(2)
        this.rates.rateBa = (this.base + (this.base * this.rateBAInc / 100)).toFixed(2)
        this.rates.rateS = (this.base + (this.base * this.rateSInc / 100)).toFixed(2)
        this.rates.rateSa = (this.base + (this.base * this.rateSAInc / 100)).toFixed(2)
      }
    },
  },
  data() {
    return {
      submitLoading: false,
      loadingProjectRates: false,
      refreshBaseLoading: false,
      activeKeys: [],
      rates: {
        base: this.currencyRateInfo.base,
        rateB: this.currencyRateInfo.rate_b,
        rateBa: this.currencyRateInfo.rate_ba,
        rateS: this.currencyRateInfo.rate_s,
        rateSa: this.currencyRateInfo.rate_sa,
      },
      currencyRateForm: {
        rate_b_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_b_inc : 0,
        rate_ba_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_ba_inc : 0,
        rate_s_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_s_inc : 0,
        rate_sa_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_sa_inc : 0,
      },
      projectRates: [],
    }
  },
  methods: {
    isSyncGlobalRates(objectImport) {
      return Object.keys(objectImport).every((key) => objectImport[key] === true)
    },
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record) {
      this.$emit('submitModalFunction', record)
    },
    submitBaseChanged(record) {
      this.$emit('updateBaseRateFunction', record)
    },
    hideCollapsePanel(key) {
      this.activeKeys = [...this.activeKeys].filter(activeKey => activeKey !== String(key))
    },
    updateProjectHandler(project) {
      const projectRateIndex = this.projectRates.findIndex((projectRate) => projectRate.id === project.id)

      if (projectRateIndex !== -1) {
        this.$set(this.projectRates, projectRateIndex, project)
      }
    },
    async editGlobalRatesSubmitHandler() {
      try {
        this.submitLoading = true
        const response = await CurrenciesRatesService.updateRateCharges(this.currencyRateInfo.id, this.currencyRateForm)
        await this.getProjectRatesByCurrency(this.currencyId)
        this.$message.success('Global currency rates updated')
        this.submitModal(response.data.data)
      } catch (error) {
        console.log(error)
        this.$message.error('Can not update rates. Message: '.error.message)
      } finally {
        this.submitLoading = false
      }
    },
    async refreshBaseRate() {
      this.refreshBaseLoading = true
      return CurrenciesRatesService.refreshBaseRate(this.currencyRateInfo.id).then((response) => {
        this.rates.base = response.data.data.rate.base
        this.submitBaseChanged(response.data.data)

        // console.log(this.rates.base)
        this.refreshBaseLoading = false
        this.$message.success('Base rate updated')
      }).catch(error => {
        this.$message.error('Can not get updated base rate')
        this.refreshBaseLoading = false
        console.log(error)
      })
    },
    async getProjectRatesByCurrency(currencyId) {
      try {
        this.loadingProjectRates = true
        this.projectRates = await ProjectsService.getProjectRatesByCurrency(currencyId)
      } catch (error) {
        this.$message.error('Unable to get rates for projects')
        console.log(error)
      } finally {
        this.loadingProjectRates = false
      }
    },
    async editProjectRates(project) {
      try {
        const preparedData = this.prepareEditableProjectRateData(project)
        const updatedProjectRates = await ProjectsService.updateProjectRates(project.id, preparedData)
        const projectRateIndex = this.projectRates.findIndex((projectRate) => projectRate.id === updatedProjectRates.id)
        if (projectRateIndex !== -1) {
          this.$set(this.projectRates, projectRateIndex, updatedProjectRates)
        }
        this.$message.success(`${project.name} project rates have been successfully updated`)
      } catch (error) {
        this.$message.error(`${project.name} project rates could not be updated`)
        console.log(error)
      } finally {
      }
    },
    prepareEditableProjectRateData(project) {
      return {
        rate_b_inc: project.rate_b_inc,
        rate_ba_inc: project.rate_ba_inc,
        rate_s_inc: project.rate_s_inc,
        rate_sa_inc: project.rate_sa_inc,
        options: {
          rate_b_inc_import: project.auto_import.rate_b_inc_import,
          rate_ba_inc_import: project.auto_import.rate_ba_inc_import,
          rate_s_inc_import: project.auto_import.rate_s_inc_import,
          rate_sa_inc_import: project.auto_import.rate_sa_inc_import,
        },
      }
    },
  },
  mounted() {
    if (this.currencyId !== undefined) {
      this.getProjectRatesByCurrency(this.currencyId)
    }
  },
}
</script>
