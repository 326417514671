<template>
  <div class="project-rate-item">
    <a-row :gutter="[16, 16]">
      <a-col :span="8">
        <a-row :gutter="[16, 16]">
          <a-col :span="12">
            <CurrenciesStatisticItem
              title="Buy"
              :abbr="project.currency_abbr"
              :agent-rate="calculateRate(project.base_rate, projectOptions.rate_ba_inc)"
              :client-rate="calculateRate(project.base_rate, projectOptions.rate_b_inc)"
            />
          </a-col>
          <a-col :span="12">
            <CurrenciesStatisticItem
              title="Sell"
              :abbr="project.currency_abbr"
              :agent-rate="calculateRate(project.base_rate, projectOptions.rate_sa_inc)"
              :client-rate="calculateRate(project.base_rate, projectOptions.rate_s_inc)"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="16">
        <CurrenciesOperations
          :options="projectOptions"
          @update:options="updateOptionsHandler"
          use-auto-import
          :isHavePermissionEdit="isHavePermissionEdit"
          @submitted="submitHandler"
          @canceled="canceledHandler"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import CurrenciesStatisticItem from './CurrenciesStatisticItem.vue'
import CurrenciesOperations from './CurrenciesOperations.vue'

export default {
  name: 'ProjectRateItem',
  components: {
    CurrenciesOperations,
    CurrenciesStatisticItem,
  },
  emits: ['update:project', 'submit', 'cancel'],
  props: {
    project: {
      type: Object,
      required: true,
    },
    isHavePermissionEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    projectOptions: {
      get() {
        return {
          rate_b_inc: this.project?.rate_b_inc ?? 0,
          rate_ba_inc: this.project?.rate_ba_inc ?? 0,
          rate_s_inc: this.project?.rate_s_inc ?? 0,
          rate_sa_inc: this.project?.rate_sa_inc ?? 0,
          options: {
            rate_b_inc_import: this.project.auto_import.rate_b_inc_import,
            rate_ba_inc_import: this.project.auto_import.rate_ba_inc_import,
            rate_s_inc_import: this.project.auto_import.rate_s_inc_import,
            rate_sa_inc_import: this.project.auto_import.rate_sa_inc_import,
          },
        }
      },
      set(value) {
        const data = this.prepareProjectData(value)
        this.$emit('update:project', data)
      },
    },
  },
  methods: {
    prepareProjectData(value) {
      const data = {
        ...this.project,
        ...value,
        auto_import: {
          ...value.options,
        },
      }
      delete data.options

      return data
    },
    updateOptionsHandler(options) {
      this.projectOptions = options
    },
    calculateRate(baseRate, rate) {
      return (baseRate + (baseRate * rate / 100)).toFixed(2)
    },
    submitHandler() {
      this.$emit('submit')
    },
    canceledHandler() {
      this.$emit('cancel')
    },
  },
}
</script>
