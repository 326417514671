import apiClient from '../../../axios'

export default class CurrenciesRatesService {
  static async updateCurrencyRate(currencyRateId, rateData) {
    try {
      const response = await apiClient.patch(`/admin/currencies/rates/${currencyRateId}`, rateData)
      return response
    } catch (error) {
      console.error('Error updating currency rate:', error)
      throw error
    }
  }

  static async updateRateCharges(currencyRateId, rateChargesData) {
    try {
      const response = await apiClient.patch(`/admin/currencies/rates/${currencyRateId}/charges`, rateChargesData)
      return response
    } catch (error) {
      console.error('Error updating rate charges:', error)
      throw error
    }
  }

  static async refreshBaseRate(currencyRateId) {
    try {
      const response = await apiClient.patch(`/admin/currencies/rates/${currencyRateId}/base`)
      return response
    } catch (error) {
      console.error('Error refreshing base rate:', error)
      throw error
    }
  }

  static async getBinanceRate(currency, sellAmount) {
    try {
      const response = await apiClient.post(`/admin/binancio/${currency}`, { amount: sellAmount })
      return response
    } catch (error) {
      console.error('Error getting Binance rate:', error)
      throw error
    }
  }

  static async getCurrencyRateDigits(params) {
    try {
      const response = await apiClient.get('/admin/currencies/rates/analytics/digits', { params })
      return response.data.data
    } catch (error) {
      console.error('Error getting currency rate digits:', error)
      throw error
    }
  }
}
