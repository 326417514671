<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0"><a-icon type="global" /> {{ $t('common.currencies') }}</span>
      <a-button type="primary" @click="showNewCurrencyModal">
        <a-icon type="plus" /> {{ $t('buttons.btnAdd') }}
      </a-button>
    </div>
    <a-table
      :columns="currenciesColumns"
      :dataSource="currenciesData"
      :rowKey="record => record.id"
      :pagination="pagination"
      :loading="currencyLoading"
    >
      <template v-slot:name="name, record">
        <span class="font-weight-bolder">{{ name }}</span>
        <span v-if="record.abbr" class="ml-1">({{ record.abbr }})</span>
        <span v-if="record.desc" class="text-gray-5 small d-block" style="word-wrap: break-word;">{{ record.desc }}</span>
      </template>
      <template v-slot:history="text, record">
        <div v-if="currencyRateHistoryData[record.name]" class="p-2">
          <CurrencyRateHistoryChart
            :chart-id="`${record.name}-rate-chart`"
            :key="`${record.name}-rate-chart`"
            :items="currencyRateHistoryData[record.name].map((obj => { return obj.base })).reverse()"
            :labels="currencyRateHistoryData[record.name].map((obj => { return $moment.parseZone(obj.created_at).format('YYYY-MM-DD HH:mm') })).reverse()"
            :title="record.name"
            :height="76"
            :width="130"
            style="width: 130px; height: 80px; max-height: 80px;"
          />
        </div>
      </template>
      <template v-slot:rate="rate, record">
        <div v-if="rate" class="">
          <a-row>
            <a-col :span="6">
              <span class="small text-gray-5 d-block font-weight-light">
                {{ `${$t('common.import')} ${$t('buttons.btnType')}` }}:
<!--                <a-tag :color="record.imp_r_type !== 'default' ? 'orange' : ''">{{ record.imp_r_type === 'default' ? 'default' : record.imp_r_type }}</a-tag>-->
<!--                <a-tag v-if="record.imp_r_type !== 'default'" color="orange">{{ record.imp_r_type }}</a-tag>-->
                <span v-if="record.imp_r_type !== 'default'" class="text-orange">{{ record.imp_r_type }}</span>
                <span v-else>default</span>
              </span>
              <span v-if="record.imp_r_type !== 'default'" class="d-block mt-2">
                <a-button size="small" @click="editCurrencyRate(record)"><a-icon type="edit" /> Edit rates</a-button>
              </span>
            </a-col>
            <a-col :span="6">
              <span class="small text-gray-5 d-block">{{ $t('common.base') }}</span>
                <span>{{ rate.base ? rate.base : rate.rate }} <span class="text-gray-5 small">{{ record.abbr }}</span></span>
            </a-col>
            <a-col :span="6">
              <span class="small text-gray-5 d-block">{{ $t('buttons.btnBuy') }} <a-icon type="line-chart" /></span>
              <span class="d-block">
                <span class="small text-gray-5 font-weight-light">{{ $t('common.client') }}: </span>
                {{ rate.rate_b ? rate.rate_b : rate.rate }} <span class="text-gray-5 small">{{ record.abbr }}</span>
              </span>
              <span class="d-block">
                <span class="small text-gray-5 font-weight-light">{{ $t('common.agent') }}: </span>
                {{ rate.rate_ba ? rate.rate_ba : rate.rate }} <span class="text-gray-5 small">{{ record.abbr }}</span>
              </span>
            </a-col>
            <a-col :span="6">
              <span class="small text-gray-5 d-block">{{ $t('buttons.btnSell') }} <a-icon type="line-chart" /></span>
              <span class="d-block">
                <span class="small text-gray-5 font-weight-light">{{ $t('common.client') }}: </span>
                {{ rate.rate_s ? rate.rate_s : rate.rate }} <span class="text-gray-5 small">{{ record.abbr }}</span>
              </span>
              <span class="d-block">
                <span class="small text-gray-5 font-weight-light">{{ $t('common.agent') }}: </span>
                {{ rate.rate_sa ? rate.rate_sa : rate.rate }} <span class="text-gray-5 small">{{ record.abbr }}</span>
              </span>
            </a-col>
          </a-row>
        </div>
      </template>
      <template v-slot:operation="text, record">
        <span>
          <a-tooltip title="Activate / Deactivate" placement="bottom">
            <a-switch v-model="record.is_active" size="small" :cId="record.id" @change="currencyActiveState($event, record.id)" />
          </a-tooltip>
        </span>
        <span class="ml-3">
          <a-tooltip title="Edit" placement="bottom">
            <a href="javascript:" class="font-weight-bolder font-size-18 text-primary" @click="showEditCurrencyModal(record)"><a-icon type="edit" theme="filled" /></a>
          </a-tooltip>
        </span>
        <span class="ml-3">
          <a-tooltip title="Delete" placement="bottom">
            <a-popconfirm
              :title="`Delete currency ${record.name}?`"
              @confirm="deleteCurrency(record)"
            >
              <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
            </a-popconfirm>
          </a-tooltip>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model="currencyModal"
      :destroyOnClose="true"
      :title="currencyObj ? `Change currency '${currencyObj.name}'` : 'Add currency'"
      :footer="null"
      class="currency-modal"
      width="680px"
    >
      <currency-modal
        :currency-info="currencyObj"
        @closeModalFunction="closeCurrencyModal"
        @submitModalFunction="currencyModalSubmit"
      />
    </a-modal>
    <a-modal
      v-model="currencyRateModal"
      :destroyOnClose="true"
      :title="currencyRateObj && currencyObj ? `${$t('common.currency')} ${$t('common.rates')} ${$t('pre.for')} ${currencyObj.name}` : ''"
      :footer="null"
      class="currency-rate-modal"
      width="1000px"
    >
      <currency-rate-modal
        :currency-rate-info="currencyRateObj"
        :currency="currencyObj"
        :import-methods="importRateMethods"
        @closeModalFunction="closeCurrencyRateModal"
        @submitModalFunction="currencyRateModalSubmit"
        @updateBaseRateFunction="updateBaseRate"
      />
    </a-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import apiClient from '@/services/axios'
import currencyModal from '@/views/currencies/currencies/currencyModal.vue'
import currencyRateModal from '@/views/currencies/currencies/currencyRateModal.vue'
import CurrencyRateHistoryChart from '@/components/charts/CurrencyRateHistoryChart.vue'
import CurrenciesService from '../../../services/api/currencies/currencies.service'
import CurrenciesRatesService from '../../../services/api/currencies/rates/currencies.rates.service'

const currenciesColumns = [
  {
    title: 'Currency',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'History',
    dataIndex: 'history',
    width: '10%',
    scopedSlots: { customRender: 'history' },
  },
  {
    title: 'Rates',
    dataIndex: 'rate',
    key: 'rate',
    width: '60%',
    scopedSlots: { customRender: 'rate' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'currenciesTable',
  components: {
    currencyModal, currencyRateModal, CurrencyRateHistoryChart,
  },
  computed: {
    ...mapGetters(['currencies']),
  },
  data() {
    return {
      currenciesData: [],
      currenciesColumns,
      currencyModal: false,
      currencyLoading: false,
      currencyRateDigitsLoading: false,
      currencyRateHistoryData: [],
      currencyRateHistoryDates: [
        this.$moment(this.$moment().utc().add(-1, 'days'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD'),
      ],
      currencySubmitLoading: false,
      currencyRateModal: false,
      currencyObj: undefined,
      currencyRateObj: undefined,
      importRateType: undefined,
      importRateMethods: ['default', 'raterat'],
      pagination: { pageSize: 15 },
    }
  },
  mounted () {
    // ---
    this.getCurrencies().then(() => this.getCurrencyRateHistoryDigits())
  },
  methods: {
    showNewCurrencyModal() {
      this.currencyObj = null
      this.currencyModal = true
    },
    showEditCurrencyModal(currency) {
      this.currencyObj = currency
      this.currencyModal = true
    },
    editCurrencyRate(currency) {
      this.currencyObj = currency
      this.currencyRateObj = currency.rate
      this.currencyRateModal = true
    },
    closeCurrencyModal() {
      this.currencyModal = false
    },
    closeCurrencyRateModal() {
      this.currencyRateModal = false
    },
    currencyModalSubmit(currency, edit) {
      if (edit) {
        const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
        this.$set(this.currenciesData, psIndex, currency)
        console.log(this.currenciesData)
      } else {
        console.log('new cur', currency)
        this.currenciesData.push(currency)
      }
      this.currencyModal = false
    },
    currencyRateModalSubmit(currency) {
      const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
      this.currenciesData[psIndex] = currency
    },
    updateBaseRate(currency) {
      // console.log('emitted', currency)
      const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
      this.$set(this.currenciesData, psIndex, currency)
      console.log('base updated', this.currenciesData[psIndex].rate.base)
    },
    async getCurrencies() {
      this.currencyLoading = true
      return CurrenciesService.getCurrencies().then((response) => {
        this.currenciesData = response
        this.currencyLoading = false
      }).catch(error => { console.log(error); this.currencyLoading = false })
    },
    async getCurrencyRateHistoryDigits() {
      this.currencyRateDigitsLoading = true
      const payload = {
        currency: this.currencies.map(currency => { return currency.name }).toString(),
        from_date: this.$moment(this.currencyRateHistoryDates[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.currencyRateHistoryDates[1]).format('YYYY-MM-DD HH:mm:ss'),
      }
      return CurrenciesRatesService.getCurrencyRateDigits(payload).then((response) => {
        this.currencyRateHistoryData = response
        this.currencyRateDigitsLoading = false
        console.log('history data', response)
      }).catch(error => { console.log(error); this.currencyRateDigitsLoading = false })
    },
    async deleteCurrency(currency) {
      return CurrenciesService.deleteCurrency(currency.id).then(() => {
        this.$notification.success({
          message: 'Currency removed',
          description: currency.name,
        })
        const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
        this.currenciesData.splice(psIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error deleting currency',
          description: error.message,
        })
      })
    },
    async currencyActiveState(checked, cId) {
      const url = '/admin/currencies/' + cId + '/active/' + +checked
      apiClient.get(url).then((response) => {
        this.$notification.success({
          message: 'Currency status changed',
          description: response.data.data.name,
        })
        const psIndex = this.currenciesData.findIndex(obj => obj.id === cId)
        this.$set(this.currenciesData, psIndex, response.data.data)
        console.log(this.currenciesData[psIndex])
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Status change error',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>
</style>
